<template>
    <list-table-layout 
        v-if="loaded"
        :header="`Payment History (${payments.length})`"
        :columns="columns"
        :model="PaymentModel"
        :records="payments"
        :row-controls-width="1"
        >
        <template v-slot:rowcontrols="{record}">
            <button @click="open(record.id)"><i class="fa-solid fa-arrow-up-right-from-square"></i></button>
            <button @click="download(record.id)"><i class="fa-regular fa-download"></i></button>
        </template>
        <template v-slot:table-controls>
            <router-link to="/billing" class="button"><i class="fa-regular fa-credit-card-front"></i> Make a Payment</router-link>
        </template>
    </list-table-layout>
</template>

<script>
import { ref } from '@vue/reactivity';
import ListTableLayout from '../../components/layouts/ListTableLayout.vue'
import PaymentModel from '../../models/PaymentModel';
import database from "@/store/database";

const columns = {
    'created' : {
        title : "Paid On",
        sortable: true,
        width: 2,
    },
    'paymentType' : {
        title : "Payment Type",
        sortable: true,
        width: 2,
    },
    'amount': {
        title: "Payment Amount",
        sortable: true,
        format: 'money',
        width: 2,
    },
    'paymentAllocations.invoice.invoiceNumber' : {
        title : "Invoice #(s)",
        sortable: true,
        link: '/invoices/',
        multiple: 'more',
        width: 3,
    },
    'paymentAllocations.invoice.purchaseOrderNumber' : {
        title: "PO #(s)",
        sortable: true,
        NA: '--',
        multiple: 'more',
        width: 3,
    },
}


export default {
    components: {
        ListTableLayout,
    },
    methods: {
        open(){
            alert("!!Opened the PDF in a new tab");
        },
        download(){
            alert("!!Downloaded the PDF")
        }
    },
    setup(){
        const promises = [];
        const loaded = ref(false);

        const payments = database[PaymentModel.name].list;

        Promise.all(promises).then(() => {
            loaded.value = true;
        });

        return {payments, loaded, PaymentModel, columns}

    },
}
</script>
<style lang="scss">

@import "../../assets/scss/variables.scss";

</style>